/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTotalDataCount = /* GraphQL */ `
  query GetTotalDataCount {
    getTotalDataCount
  }
`;
export const getDashboardWidget = /* GraphQL */ `
  query GetDashboardWidget($survey_id: String!, $name: String!) {
    getDashboardWidget(survey_id: $survey_id, name: $name) {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listDashboardWidgets = /* GraphQL */ `
  query ListDashboardWidgets(
    $survey_id: String
    $name: ModelStringKeyConditionInput
    $filter: ModelDashboardWidgetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDashboardWidgets(
      survey_id: $survey_id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        survey_id
        name
        field
        group_by
        value
        legend
        y_axis_title
        component_type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDashboardWidgets = /* GraphQL */ `
  query SyncDashboardWidgets(
    $filter: ModelDashboardWidgetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDashboardWidgets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_id
        name
        field
        group_by
        value
        legend
        y_axis_title
        component_type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveyUser = /* GraphQL */ `
  query GetSurveyUser($id: ID!) {
    getSurveyUser(id: $id) {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSurveyUsers = /* GraphQL */ `
  query ListSurveyUsers(
    $filter: ModelSurveyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        restricted_field
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveyUsers = /* GraphQL */ `
  query SyncSurveyUsers(
    $filter: ModelSurveyUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveyUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        restricted_field
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveys = /* GraphQL */ `
  query SyncSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveySection = /* GraphQL */ `
  query GetSurveySection($survey_id: String!, $section_id: String!) {
    getSurveySection(survey_id: $survey_id, section_id: $section_id) {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSurveySections = /* GraphQL */ `
  query ListSurveySections(
    $survey_id: String
    $section_id: ModelStringKeyConditionInput
    $filter: ModelSurveySectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveySections(
      survey_id: $survey_id
      section_id: $section_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        survey_id
        section_id
        section_content
        ordering
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveySections = /* GraphQL */ `
  query SyncSurveySections(
    $filter: ModelSurveySectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveySections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_id
        section_id
        section_content
        ordering
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveyResult = /* GraphQL */ `
  query GetSurveyResult($id: ID!) {
    getSurveyResult(id: $id) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSurveyResults = /* GraphQL */ `
  query ListSurveyResults(
    $filter: ModelSurveyResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        id
        survey_result
        mode
        survey_detail
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        updated_at
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveyResults = /* GraphQL */ `
  query SyncSurveyResults(
    $filter: ModelSurveyResultFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveyResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        owner
        id
        survey_result
        mode
        survey_detail
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        updated_at
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveyResultArchive = /* GraphQL */ `
  query GetSurveyResultArchive(
    $survey_id: String!
    $survey_result_id: String!
  ) {
    getSurveyResultArchive(
      survey_id: $survey_id
      survey_result_id: $survey_result_id
    ) {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSurveyResultArchives = /* GraphQL */ `
  query ListSurveyResultArchives(
    $survey_id: String
    $survey_result_id: ModelStringKeyConditionInput
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyResultArchives(
      survey_id: $survey_id
      survey_result_id: $survey_result_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyResultArchiveBySurveyDone = /* GraphQL */ `
  query ListSurveyResultArchiveBySurveyDone(
    $survey_id: String
    $completedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResultArchiveBySurveyDone(
      survey_id: $survey_id
      completedAt: $completedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyResultArchiveByDistrict = /* GraphQL */ `
  query ListSurveyResultArchiveByDistrict(
    $survey_id: String
    $districtCompletedAt: ModelSurveyResultArchiveByDistrictCompletedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResultArchiveByDistrict(
      survey_id: $survey_id
      districtCompletedAt: $districtCompletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyResultArchiveByDivisionalSecretariat = /* GraphQL */ `
  query ListSurveyResultArchiveByDivisionalSecretariat(
    $survey_id: String
    $divisional_secretariatCompletedAt: ModelSurveyResultArchiveByDivisionalSecretariatCompletedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResultArchiveByDivisionalSecretariat(
      survey_id: $survey_id
      divisional_secretariatCompletedAt: $divisional_secretariatCompletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyResultArchiveByGNDivision = /* GraphQL */ `
  query ListSurveyResultArchiveByGNDivision(
    $survey_id: String
    $gn_divisionCompletedAt: ModelSurveyResultArchiveByGNDivisionCompletedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResultArchiveByGNDivision(
      survey_id: $survey_id
      gn_divisionCompletedAt: $gn_divisionCompletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyResultArchiveByMode = /* GraphQL */ `
  query ListSurveyResultArchiveByMode(
    $survey_id: String
    $modeCompletedAt: ModelSurveyResultArchiveByModeCompletedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResultArchiveByMode(
      survey_id: $survey_id
      modeCompletedAt: $modeCompletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyResultArchiveByUser = /* GraphQL */ `
  query ListSurveyResultArchiveByUser(
    $survey_id: String
    $surveyResultArchiveUserIdCompletedAt: ModelSurveyResultArchiveByUserCompletedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResultArchiveByUser(
      survey_id: $survey_id
      surveyResultArchiveUserIdCompletedAt: $surveyResultArchiveUserIdCompletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveyResultArchives = /* GraphQL */ `
  query SyncSurveyResultArchives(
    $filter: ModelSurveyResultArchiveFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveyResultArchives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_id
        survey_result_id
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        submittedAt
        completedAt
        createdAt
        updatedAt
        surveyResultArchiveUserId
        mode
        owner
        your_house
        sanitation_facility
        electricity_in_your_house
        primary_normal_season
        employment_primary_job
        are_you_a_samurdhi_beneficiary
        younger_fifteen_year_dependents
        disabled_dependents
        elder_dependents
        are_you_receiving_public_monthly_assistance_currently
        highest_education_head_household
        value_of_the_samurdhi_stamp
        family_members_receiving_family_public_assistance_total
        family_public_assistance_elders_assistance
        family_public_assistance_disability_assistance
        family_public_assistance_assistance_disease
        family_public_assistance_kidney
        family_public_assistance_tb
        family_public_assistance_leprosy
        family_public_assistance_cancer
        family_public_assistance_spinal_code_injury
        total_income_family
        age_head_household
        no_of_dependent
        sex_of_head_household
        widowed_reason
        do_you_have_own_land
        do_you_have_own_house
        head_household_disabled
        head_of_household
        interest_self_employment
        access_to_well
        count
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveyAnalysisConfiguration = /* GraphQL */ `
  query GetSurveyAnalysisConfiguration(
    $survey_id: String!
    $metric_name: String!
  ) {
    getSurveyAnalysisConfiguration(
      survey_id: $survey_id
      metric_name: $metric_name
    ) {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSurveyAnalysisConfigurations = /* GraphQL */ `
  query ListSurveyAnalysisConfigurations(
    $survey_id: String
    $metric_name: ModelStringKeyConditionInput
    $filter: ModelSurveyAnalysisConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyAnalysisConfigurations(
      survey_id: $survey_id
      metric_name: $metric_name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        survey_id
        metric_name
        title
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveyAnalysisConfigurations = /* GraphQL */ `
  query SyncSurveyAnalysisConfigurations(
    $filter: ModelSurveyAnalysisConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveyAnalysisConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_id
        metric_name
        title
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveyAnalysisResult = /* GraphQL */ `
  query GetSurveyAnalysisResult($survey_id: String!, $metric_name: String!) {
    getSurveyAnalysisResult(survey_id: $survey_id, metric_name: $metric_name) {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSurveyAnalysisResults = /* GraphQL */ `
  query ListSurveyAnalysisResults(
    $survey_id: String
    $metric_name: ModelStringKeyConditionInput
    $filter: ModelSurveyAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyAnalysisResults(
      survey_id: $survey_id
      metric_name: $metric_name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        survey_id
        metric_name
        title
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        final_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyAnalysisResultByDistrictCompletedAt = /* GraphQL */ `
  query ListSurveyAnalysisResultByDistrictCompletedAt(
    $survey_id: String
    $district: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyAnalysisResultByDistrictCompletedAt(
      survey_id: $survey_id
      district: $district
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        metric_name
        title
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        final_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyAnalysisResultByDSCompletedAt = /* GraphQL */ `
  query ListSurveyAnalysisResultByDSCompletedAt(
    $survey_id: String
    $divisional_secretariat: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyAnalysisResultByDSCompletedAt(
      survey_id: $survey_id
      divisional_secretariat: $divisional_secretariat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        metric_name
        title
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        final_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyAnalysisResultByGNCompletedAt = /* GraphQL */ `
  query ListSurveyAnalysisResultByGNCompletedAt(
    $survey_id: String
    $gn_division: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyAnalysisResultByGNCompletedAt(
      survey_id: $survey_id
      gn_division: $gn_division
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        metric_name
        title
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        final_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listSurveyAnalysisResultGroupBy = /* GraphQL */ `
  query ListSurveyAnalysisResultGroupBy(
    $survey_id: String
    $group_by: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyAnalysisResultGroupBy(
      survey_id: $survey_id
      group_by: $group_by
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        survey_id
        metric_name
        title
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        final_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveyAnalysisResults = /* GraphQL */ `
  query SyncSurveyAnalysisResults(
    $filter: ModelSurveyAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveyAnalysisResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_id
        metric_name
        title
        district
        divisional_secretariat
        gn_division
        district_
        divisional_secretariat_
        gn_division_
        execution_frequency
        group_by
        measured_field
        measured_value
        measured_against_field
        measured_against_value
        measured_against_fields
        measured_against_values
        multi_field_mode
        measurement_type
        next_update
        final_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSurveyExport = /* GraphQL */ `
  query GetSurveyExport($survey_id: String!, $exported_at: String!) {
    getSurveyExport(survey_id: $survey_id, exported_at: $exported_at) {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSurveyExports = /* GraphQL */ `
  query ListSurveyExports(
    $survey_id: String
    $exported_at: ModelStringKeyConditionInput
    $filter: ModelSurveyExportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyExports(
      survey_id: $survey_id
      exported_at: $exported_at
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        survey_id
        export_id
        exported_at
        initial_date
        last_date
        custom_filter_index
        custom_filter_field
        custom_filter_value
        s3_link
        status
        filters
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSurveyExports = /* GraphQL */ `
  query SyncSurveyExports(
    $filter: ModelSurveyExportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSurveyExports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        survey_id
        export_id
        exported_at
        initial_date
        last_date
        custom_filter_index
        custom_filter_field
        custom_filter_value
        s3_link
        status
        filters
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const GetSurveyExportByExportId = /* GraphQL */ `
  query GetSurveyExportByExportId($exportId: String!) {
  listSurveyExports(filter: { export_id: { eq: $exportId } }, limit: 1) {
    items {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
}
`;
